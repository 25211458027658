/* Clio */

:root {
    --text: #ffffff;
    --font: "Helvetica Neue", Helvetica, Arial, sans-serif;
    --default-state-background: #0070e0;
    --default-state-border: #0f478d;
    --hover-state-background: var(--default-state-border);
    --focus-state-shadow: var(--default-state-background);
    --focus-state-border: #ffffff;
  }
  
  .ClioButton {
    height: 32px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid var(--default-state-border);
    background-color: var(--default-state-background);
    background-image: linear-gradient(-180deg, var(--default-state-background) 0%, var(--default-state-border) 100%);
    font-family: var(--font);
    color: var(--text);
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    align-items: center;
    display: flex;
    position: relative;
    box-sizing: border-box;
    margin-top: 2em;
    align-items: center;
    justify-content: center;
  }
  
  .ClioButton:hover, .ClioButton:focus {
    background-image: linear-gradient(-180deg, var(--hover-state-background) 0%, var(--hover-state-background) 100%);
    text-decoration: none;
  }
  
  .ClioButton:hover:before {
    content: "";
    border-radius: 0 0 1px 1px;
    border-bottom: 2px solid var(--hover-state-underline);
    position: absolute;
    bottom: 1px;
    left: 1px;
    right: 1px;
  }
  
  .ClioButton:focus:after {
    box-shadow: 0 0 0 2px var(--focus-state-shadow);
    content: "";
    border-radius: 4px;
    border: 1px solid var(--focus-state-border);
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
  }
  
  .ClioButton img {
    padding-right: 8px;
    height: 16px;
    width: 16px;
  }